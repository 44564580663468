@import '../../styles/colors.scss';
.pwRequirements {
  ul {
    font-size: 14px;
    margin-top: 0px;
    li {
      padding: 2px;
      min-height: 18px;
      list-style-image: url('../../assets/images/X-sm.png');
      color: map-get($colors, 'red');
      &.passes {
        list-style-image: url('../../assets/images/checkmark-sm.png');
        color: map-get($colors, 'green');
      }
    }
  }
}
