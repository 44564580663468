@import '../../styles/globals.scss';
@import '../../styles/colors.scss';

.header {
  width: 100%;
  height: $headerHeight;
  background: #fff;
  box-shadow: 0px 1px 1px #00000040;
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  .noCenter {
    grid-template-columns: 1fr 0 2fr;
  }

  /* Needed to stop the grid elements from growing. */
  min-width: 0;
  min-height: 0;

  .headerButton {
    margin: 0;
  }
}

.headerInfo {
  display: flex;

  /* Needed to stop the grid elements from growing. */
  min-width: 0;
  min-height: 0;
  overflow: hidden;
}

.backButton {
  width: 40px;
  height: $headerHeight;
  margin: 0;
  padding: 0;
  border: none;

  > img {
    width: inherit;
    height: inherit;
    background: url('../../assets/images/backButtonBlue.png') 100% no-repeat;
    background-size: contain;
    content: '';
    display: inline-block;
    &.unsaved {
      background: url('../../assets/images/Back Button.png') 100% no-repeat;
      background-size: contain;
      content: '';
    }
  }

  &:hover {
    cursor: pointer;
    > img {
      background: url('../../assets/images/backButtonBlueHover.png') 100%
        no-repeat;
      background-size: contain;
      content: '';
      &.unsaved {
        background: url('../../assets/images/backButtonHover.png') 100%
          no-repeat;
        background-size: contain;
      }
    }
  }
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  :global(.input) {
    margin: 0;
  }
}

.title {
  display: flex;
  flex-direction: column;
  max-height: inherit;
  text-align: left;
  justify-content: center;
  margin-left: 10px;

  /* Needed to stop the grid elements from growing. */
  overflow: hidden;

  > div {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;

    &:not(:only-child):first-child {
      font-size: 18px;
      line-height: 21px;
      font-weight: normal;
    }
  }
}

.buttons {
  grid-column: 3/4;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  :global(.input) {
    margin: 0;
  }
}

.pageActions {
  grid-column: 3/4;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 10px;
  position: relative;
  z-index: 10;

  h2 {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-align: right;
    color: map-get($colors, 'blue');
    cursor: pointer;

    img {
      margin-left: 10px;
      width: 20px;
      height: 10px;
      filter: brightness(0) saturate(100%) invert(29%) sepia(85%)
        saturate(1689%) hue-rotate(157deg) brightness(91%) contrast(103%);
    }
  }

  .dropdown {
    height: fit-content;
    position: absolute;
    right: 0;
    background-color: white;
    top: 100%;
    width: fit-content;
    padding: 10px;
    filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.5));
    cursor: pointer;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    .dropdownOption {
      font-weight: bold;
      font-size: 16px;
      text-align: left;
      margin: 0;
      padding: 7px;
      line-height: 18px;
      border-radius: 5px;
      &:hover {
        background-color: map-get($colors, 'white-hover');
      }

      @include colorStyling($text: true);
    }
  }
}

.section {
  display: flex;

  .sectionName {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.input {
  margin: 0;
  padding: 0;
  > input {
    padding: 7.5px;
  }
}

.edit {
  display: block;
  width: 30px;
  height: 30px;
  cursor: pointer;
  > img {
    content: url('../../assets/images/editName.png');
    width: inherit;
    height: inherit;
    &:hover {
      content: url('../../assets/images/editNameHover.png');
    }
  }
}

.chevron {
  scale: 62%;
  transition: 0.3s;
}

.hideShadow {
  box-shadow: none;
}

.lengthWarning {
  margin: 5px 0px 0px 10px;
  font-size: 16px;
  color: map-get($colors, 'red');
}
